import { useContext } from "react";
import { AuthContext } from "../service/UserContext";
import { googleProvider, facebookProvider } from "../config/authMethods";
import socialMediaAuth from "../service/auth";
import firebase from "../config/firebase-config";
import { Redirect } from "react-router";
import { motion } from "framer-motion";
import GoogleLogo from "./svg/GoogleLogo.svg";
import ReaderLogo from "./svg/ReaderLogo.js";
import FacebookLogo from "./svg/FacebookLogo.svg";
import LoginDetails from "./png/login_details.png";

const Login = ({ setLoading, isLoading }) => {
  const { currentUser } = useContext(AuthContext);
  const el = document.querySelector(".loader-container");
  

  if (!isLoading && el) {
    el.remove();
  }

  const handleLogin = async (provider) => {
    const res = await socialMediaAuth(provider);
    console.log(res);
    //setLoading(false);

    if (currentUser) {
      const createSettings = () => {
        const settingsRef = firebase
          .database()
          .ref("users/" + currentUser.uid + "/settings");
        const setting = {
          colorScheme: true,
        };

        settingsRef.push(setting);
      };

      createSettings();
    }
  };

  const handleSignOut = () => {
    firebase.auth().signOut();
  };

  console.log(currentUser);

  return (
    <div className="page page_login">
      {currentUser && <Redirect to="/" />}
      <div className="container logo-container">
        <ReaderLogo />

        <p>Track your reading progress</p>
      </div>
      <div className="container">
        <motion.img
          src={LoginDetails}
          alt="Login details"
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          className="login-detail"
        />
      </div>
      <div className="container">
        {currentUser ? (
          <button className="btn" onClick={handleSignOut}>
            Sign out
          </button>
        ) : (
          <div className="button-group">
            <button className="btn" onClick={() => handleLogin(googleProvider)}>
              <img src={GoogleLogo} alt="Google Logo" />
              <span>Continue with Google</span>
            </button>
            <button
              className="btn"
              onClick={() => handleLogin(facebookProvider)}
            >
              <img src={FacebookLogo} alt="Facebook Logo" />
              <span>Continue with Facebook</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
