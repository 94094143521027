import { motion } from "framer-motion";
// import { useState } from "react";

const Popup = ({ children, handlePopup }) => {
  //const [dragged, setDragged] = useState(0);

  /*   const handleDrag = (y) => {
    setDragged(y);
  }; */

  //console.log(dragged);

  /*   if (dragged > 600) {
    handlePopup();
  } */

  return (
    <motion.div
      className="popup"
      key="2"
      //drag="y"
      //dragConstraints={{ top: 0, bottom: 200 }}
      //dragElastic={0}
      //onDragEnd={(event, info) => handleDrag(info.point.y)}
      //dragMomentum={false}
      initial={{
        opacity: 0,
        y: 400,
      }}
      animate={{
        opacity: 1,
        y: 0,
      }}
      transition={{
        duration: 0.3,
      }}
      exit={{
        opacity: 0,
        y: 400,
      }}
    >
      <ul>{children}</ul>
    </motion.div>
  );
};

export default Popup;
