// Import Utility
import React, { lazy, Suspense } from "react";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Redirect } from "react-router";
import { useAuth } from "./service/UserContext";
import firebase from "./config/firebase-config";
import "./App.css";

// Import Components
import Navigation from "./components/Navigation";
import ScrollToTop from "./components/ScrollToTop";

import Login from "./components/Login";

const Home = lazy(() => import("./components/Home"));
const Library = lazy(() => import("./components/Library"));
const Statistics = lazy(() => import("./components/Statistics"));
const Settings = lazy(() => import("./components/Settings"));
const BookPage = lazy(() => import("./components/BookPage"));
const GoogleBooks = lazy(() => import("./components/GoogleBooks"));
const AddCustomBook = lazy(() => import("./components/AddCustomBook"));
const Activity = lazy(() => import("./components/Activity"));
const LogPage = lazy(() => import("./components/LogPage"));
const ManuallyAdd = lazy(() => import("./components/ManuallyAdd"));
const Profile = lazy(() => import("./components/Profile"));
const EmailLogin = lazy(() => import("./components/emailLogin"));
const CreateCollection = lazy(() =>
  import("./components/collections/CreateCollection")
);
const CollectionPage = lazy(() =>
  import("./components/collections/CollectionPage")
);

function App() {
  const [books, setBooks] = useState([]);
  const [logs, setLogs] = useState([]);
  const { currentUser } = useAuth();
  const [colorTheme, setColorTheme] = useState(true);
  const [userColorTheme, setUserColorTheme] = useState();
  const [userPrimaryColor, setUserPrimaryColor] = useState();
  const [isLoading, setLoading] = useState(false);
  const el = document.querySelector(".loader-container");
  let [showCollections, setShowCollections] = useState(false);
  const [activeYear, setActiveYear] = useState("all-time");

  useEffect(() => {
    if (currentUser) {
      setLoading(true);
    }
  }, []);

  // Get Firebase Books
  useEffect(() => {
    if (currentUser) {
      const bookRef = firebase
        .database()
        .ref("users/" + currentUser.uid + "/books");

      bookRef.on("value", (snapshot) => {
        const firebaseBooks = snapshot.val();
        const bookList = [];

        for (let id in firebaseBooks) {
          bookList.push({ id, ...firebaseBooks[id] });
        }
        setBooks(bookList);
        setLoading(false);

        if (el) {
          el.remove();
        }
      });
    }
  }, [currentUser]);

  // Get Firebase logs
  useEffect(() => {
    if (currentUser) {
      const logRef = firebase
        .database()
        .ref("users/" + currentUser.uid + "/logs");

      logRef.on("value", (snapshot) => {
        const firebaseLogs = snapshot.val();
        const logList = [];

        for (let id in firebaseLogs) {
          logList.push(firebaseLogs[id]);
        }
        setLogs(logList);
        setLoading(false);
        if (el) {
          el.remove();
        }
      });
    }
  }, [currentUser]);

  // Get Firebase User settings
  useEffect(() => {
    if (currentUser) {
      const colorRef = firebase
        .database()
        .ref("users/" + currentUser.uid + "/settings/colorSettings");

      if (colorRef) {
        colorRef.on("value", (snapshot) => {
          const firebaseSettings = snapshot.val();
          const settingList = [];

          console.log(firebaseSettings);

          for (let id in firebaseSettings) {
            settingList.push({ id, ...firebaseSettings[id] });
          }

          let mainColor = "";
          let colorTheme = "";

          if (firebaseSettings) {
            mainColor = firebaseSettings.primary_color;
          }
          if (firebaseSettings) {
            colorTheme = firebaseSettings.color_theme;
          }

          setUserPrimaryColor(mainColor);
          setUserColorTheme(colorTheme);
        });
      }
    }
  }, [
    userColorTheme,
    userPrimaryColor,
    setUserColorTheme,
    setUserPrimaryColor,
  ]);

  //console.log(books);

  // Fetch Books
  /*   const fetchBooks = async () => {
    const res = await fetch("http://localhost:5000/books");
    const data = await res.json();

    return data;
  };

  useEffect(() => {
    const getBooks = async () => {
      const booksFromDB = await fetchBooks();
      setBooks(booksFromDB);
    };

    getBooks();
  }, []); */

  // Fetch Logs
  /*   const fetchLogs = async () => {
    const res = await fetch("http://localhost:5000/logs");
    const data = await res.json();

    return data;
  };

  useEffect(() => {
    const getLogs = async () => {
      const logsFromDB = await fetchLogs();
      setLogs(logsFromDB);
    };



    getLogs();
  }, []); */
  const body = document.getElementById("body");

  useEffect(() => {
    if (userColorTheme && userColorTheme === "dark-mode") {
      body.classList.add("dark-mode");
      body.classList.remove("light-mode");
    }

    if (userColorTheme && userColorTheme === "light-mode") {
      body.classList.add("light-mode");
      body.classList.remove("dark-mode");
    }
  }, [userColorTheme]);

  // Define primary colors
  const primaryColors = [
    {
      color: "#3772ff",
      name: "dodger-blue",
    },
    {
      color: "#EF8262",
      name: "burnt-sienna",
    },
    {
      color: "#7469E3",
      name: "medium-purple",
    },
    {
      color: "#45B26B",
      name: "chateau-green",
    },
    {
      color: "#EF466F",
      name: "french-rose",
    },
  ];
  const [primaryColor, setprimaryColor] = useState();

  const handleLightMode = () => {
    updateThemeSettings("light-mode");
  };

  const handleDarkMode = () => {
    updateThemeSettings("dark-mode");
  };

  const changeColorTheme = () => {
    setColorTheme(!colorTheme);
  };

  const chooseColor = (color) => {
    updateColorSettings(color);
  };

  const updateColorSettings = (color) => {
    if (currentUser) {
      const bookRef = firebase
        .database()
        .ref("users/" + currentUser.uid + "/settings");

      bookRef.once("child_added", function (snapshot) {
        snapshot.ref.update({
          primary_color: color,
        });
      });
    }
  };

  const updateThemeSettings = (theme) => {
    if (currentUser) {
      const bookRef = firebase
        .database()
        .ref("users/" + currentUser.uid + "/settings");

      bookRef.once("child_added", function (snapshot) {
        snapshot.ref.update({
          color_theme: theme,
        });
      });
    }
  };

  // Define global Genrelist
  const genreList = [
    {
      label: "Adventure",
      value: "adventure",
    },
    {
      label: "Biography",
      value: "biography",
    },
    {
      label: "Children",
      value: "children",
    },
    {
      label: "Classics",
      value: "classics",
    },
    {
      label: "Contemporary",
      value: "contemporary",
    },
    {
      label: "Dystopian",
      value: "dystopian",
    },
    {
      label: "Fantasy",
      value: "fantasy",
    },
    {
      label: "Fiction",
      value: "fiction",
    },
    {
      label: "Historical",
      value: "historical",
    },
    {
      label: "Horror",
      value: "horror",
    },
    {
      label: "Short stories",
      value: "short-stories",
    },
    {
      label: "Mystery",
      value: "mystery",
    },
    {
      label: "Non Fiction",
      value: "non-fiction",
    },
    {
      label: "Paranormal",
      value: "paranormal",
    },
    {
      label: "Romance",
      value: "romance",
    },
    {
      label: "Sci-Fi",
      value: "sci-fi",
    },
    {
      label: "Self-help",
      value: "self-help",
    },
    {
      label: "Thriller",
      value: "thriller",
    },
    {
      label: "True crime",
      value: "true-crime",
    },
    {
      label: "Travel",
      value: "travel",
    },
    {
      label: "Young Adult",
      value: "young-adult",
    },
    {
      label: "Mental health",
      value: "mental-health",
    },
    {
      label: "Graphic novel",
      value: "graphic-novel",
    },
    {
      label: "Middle grade",
      value: "middle-grade",
    },
    {
      label: "Adult",
      value: "adult",
    },
    {
      label: "Verse Novel",
      value: "verse-novel",
    },
    {
      label: "LGBTQ+",
      value: "lgbtq",
    },
    {
      label: "Mythology",
      value: "mythology",
    },
  ];

  const timeOptions = [
    {
      title: "Last 7 days",
      timeFrame: 7,
    },
    {
      title: "Last 14 days",
      timeFrame: 14,
    },
    {
      title: "Last 30 days",
      timeFrame: 30,
    },
  ];

  const [timeFrame, setTimeFrame] = useState(timeOptions[0].timeFrame);

  if (isLoading) {
    return null;
  }

  const collectionTab = () => {
    setShowCollections(true);
  };

  const booksTab = () => {
    setShowCollections(false);
  };

  return (
    <Router>
      {!currentUser && <Redirect to="/login" />}
      <ScrollToTop />
      <div
        id="App"
        className={
          "App " +
          (userColorTheme ? userColorTheme : "dark-mode") +
          " " +
          (userPrimaryColor ? userPrimaryColor : "dodger-blue")
        }
      >
        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          }
        >
          <Switch>
            <Route exact path="/">
              <Home logs={logs} books={books}></Home>
            </Route>
            <Route path="/library">
              <Library
                books={books}
                collectionTab={collectionTab}
                booksTab={booksTab}
                showCollections={showCollections}
              />
            </Route>
            <Route path="/statistics">
              <Statistics
                logs={logs}
                setLogs={setLogs}
                books={books}
                timeFrame={timeFrame}
                setTimeFrame={setTimeFrame}
                timeOptions={timeOptions}
                activeYear={activeYear}
                setActiveYear={setActiveYear}
              />
            </Route>
            <Route path="/settings">
              <Settings
                colorTheme={colorTheme}
                setColorTheme={setColorTheme}
                changeColorTheme={changeColorTheme}
                primaryColor={primaryColor}
                setprimaryColor={setprimaryColor}
                handleLightMode={handleLightMode}
                handleDarkMode={handleDarkMode}
                chooseColor={chooseColor}
                primaryColors={primaryColors}
                userColorTheme={userColorTheme}
                userPrimaryColor={userPrimaryColor}
              />
            </Route>
            <Route path="/book/:id">
              <BookPage genreList={genreList} />
            </Route>
            <Route path="/google-books">
              <GoogleBooks />
            </Route>
            <Route path="/add-new/:id">
              <AddCustomBook genreList={genreList} />
            </Route>
            <Route path="/custom-book/">
              <ManuallyAdd genreList={genreList} />
            </Route>
            <Route path="/login">
              <Login setLoading={setLoading} isLoading={isLoading} />
            </Route>
            <Route path="/activity">
              <Activity logs={logs} />
            </Route>
            <Route path="/log/:id">
              <LogPage />
            </Route>
            <Route path="/new-collection">
              <CreateCollection />
            </Route>
            <Route path="/collection/:id">
              <CollectionPage />
            </Route>
            <Route path="/profile">
              <Profile
                colorTheme={colorTheme}
                setColorTheme={setColorTheme}
                changeColorTheme={changeColorTheme}
                primaryColor={primaryColor}
                setprimaryColor={setprimaryColor}
                handleLightMode={handleLightMode}
                handleDarkMode={handleDarkMode}
                chooseColor={chooseColor}
                primaryColors={primaryColors}
                userColorTheme={userColorTheme}
                userPrimaryColor={userPrimaryColor}
              />
            </Route>
            <Route path="/email-login">
              <EmailLogin />
            </Route>
          </Switch>
        </Suspense>

        <Navigation />
      </div>
    </Router>
  );
}

export default App;
