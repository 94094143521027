import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/analytics";
import "firebase/app-check";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyALChm8wcj60p-pdyHN6Jb_suHq2co9guU",
  authDomain: "readr-323613.firebaseapp.com",
  databaseURL:
    "https://readr-323613-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "readr-323613",
  storageBucket: "readr-323613.appspot.com",
  messagingSenderId: "963176847624",
  appId: "1:963176847624:web:ec52c19e64d26fe810f4bd",
  measurementId: "G-N7VDK9EQFJ",
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const appCheck = firebase.appCheck();
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
appCheck.activate(
  "6LcWMBwcAAAAABRxKfsbD2daJDdeIEYXFz5I2M0u",

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  true
);

export default firebase;
