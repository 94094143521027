const ReaderLogo = () => {
  return (
    <svg
      width="197"
      height="53"
      viewBox="0 0 197 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 52.1311V1.44809H18.5732C22.6202 1.44809 25.9446 2.148 28.5463 3.54782C31.1962 4.89936 33.1715 6.75774 34.4724 9.12295C35.7732 11.4399 36.4236 14.0223 36.4236 16.8702C36.4236 19.9595 35.6046 22.7832 33.9665 25.3415C32.3766 27.8998 29.8712 29.7582 26.4505 30.9167L36.9295 52.1311H26.306L16.911 32.1475H9.25045V52.1311H0ZM9.25045 25.3415H17.995C21.0785 25.3415 23.3429 24.5934 24.7883 23.097C26.2337 21.6006 26.9564 19.6216 26.9564 17.1598C26.9564 14.7464 26.2337 12.8156 24.7883 11.3675C23.3911 9.9194 21.1026 9.19535 17.9227 9.19535H9.25045V25.3415Z"
        fill="var(--text-color)"
      />
      <path
        d="M60.0393 53C56.4258 53 53.2219 52.2277 50.4275 50.6831C47.6331 49.1384 45.4409 46.9663 43.851 44.1667C42.2611 41.367 41.4661 38.133 41.4661 34.4645C41.4661 30.7477 42.237 27.4413 43.7787 24.5451C45.3686 21.6489 47.5367 19.4044 50.2829 17.8115C53.0774 16.1703 56.3535 15.3497 60.1115 15.3497C63.6286 15.3497 66.7362 16.122 69.4343 17.6667C72.1323 19.2113 74.2281 21.3352 75.7217 24.0383C77.2634 26.6931 78.0343 29.6617 78.0343 32.944C78.0343 33.475 78.0102 34.0301 77.962 34.6093C77.962 35.1885 77.9379 35.7919 77.8897 36.4194H50.6443C50.837 39.219 51.8006 41.4153 53.5351 43.0082C55.3177 44.6011 57.4617 45.3975 59.967 45.3975C61.846 45.3975 63.4118 44.9872 64.6645 44.1667C65.9653 43.2978 66.9289 42.1876 67.5553 40.8361H76.9502C76.2757 43.1047 75.1435 45.1803 73.5536 47.0628C72.0119 48.8971 70.0847 50.3452 67.7721 51.4071C65.5076 52.469 62.93 53 60.0393 53ZM60.1115 22.8798C57.8471 22.8798 55.8477 23.5314 54.1132 24.8347C52.3787 26.0897 51.2706 28.0205 50.7888 30.627H68.6393C68.4948 28.2618 67.6275 26.3793 66.0376 24.9795C64.4477 23.5797 62.4723 22.8798 60.1115 22.8798Z"
        fill="var(--text-color)"
      />
      <path
        d="M98.1725 53C95.089 53 92.5596 52.5173 90.5843 51.5519C88.6089 50.5382 87.1394 49.2108 86.1759 47.5697C85.2123 45.9285 84.7305 44.1184 84.7305 42.1393C84.7305 38.8087 86.0313 36.1056 88.633 34.0301C91.2347 31.9545 95.1372 30.9167 100.341 30.9167H109.447V30.0478C109.447 27.5861 108.748 25.776 107.351 24.6175C105.954 23.459 104.219 22.8798 102.147 22.8798C100.268 22.8798 98.6302 23.3383 97.233 24.2555C95.8358 25.1243 94.9686 26.4276 94.6313 28.1653H85.5977C85.8386 25.5587 86.7058 23.2901 88.1994 21.3593C89.7411 19.4285 91.7165 17.9563 94.1255 16.9426C96.5344 15.8807 99.2325 15.3497 102.22 15.3497C107.327 15.3497 111.35 16.6289 114.289 19.1872C117.228 21.7454 118.697 25.3657 118.697 30.0478V52.1311H110.82L109.952 46.3388C108.892 48.2696 107.399 49.8625 105.472 51.1175C103.593 52.3725 101.16 53 98.1725 53ZM100.268 45.7596C102.918 45.7596 104.966 44.8907 106.411 43.153C107.905 41.4153 108.844 39.2673 109.23 36.709H101.352C98.8952 36.709 97.1367 37.1676 96.0767 38.0847C95.0168 38.9536 94.4868 40.0396 94.4868 41.3429C94.4868 42.7427 95.0168 43.8288 96.0767 44.6011C97.1367 45.3734 98.5339 45.7596 100.268 45.7596Z"
        fill="var(--text-color)"
      />
      <path
        d="M144.002 53C140.629 53 137.618 52.1794 134.968 50.5382C132.318 48.8971 130.223 46.6525 128.681 43.8046C127.139 40.9567 126.368 37.7227 126.368 34.1025C126.368 30.4822 127.139 27.2723 128.681 24.4727C130.223 21.6248 132.318 19.4044 134.968 17.8115C137.618 16.1703 140.629 15.3497 144.002 15.3497C146.7 15.3497 149.061 15.8566 151.084 16.8702C153.108 17.8839 154.746 19.3078 155.999 21.1421V0H165.249V52.1311H157.01L155.999 46.9904C154.842 48.5833 153.301 49.9832 151.373 51.1899C149.494 52.3966 147.037 53 144.002 53ZM145.953 44.8907C148.94 44.8907 151.373 43.9012 153.252 41.9221C155.18 39.8948 156.143 37.3124 156.143 34.1749C156.143 31.0373 155.18 28.4791 153.252 26.5C151.373 24.4727 148.94 23.459 145.953 23.459C143.014 23.459 140.581 24.4485 138.654 26.4276C136.727 28.4066 135.763 30.9649 135.763 34.1025C135.763 37.24 136.727 39.8224 138.654 41.8497C140.581 43.877 143.014 44.8907 145.953 44.8907Z"
        fill="var(--text-color)"
      />
      <path
        d="M174.669 52.1311V16.2186H182.908L183.775 22.9522C185.076 20.6352 186.834 18.801 189.05 17.4495C191.315 16.0496 193.965 15.3497 197 15.3497V25.1243H194.398C192.375 25.1243 190.568 25.4381 188.978 26.0656C187.388 26.6931 186.136 27.7791 185.22 29.3238C184.353 30.8684 183.919 33.0164 183.919 35.7678V52.1311H174.669Z"
        fill="var(--text-color)"
      />
    </svg>
  );
};

export default ReaderLogo;
