import { useState } from "react";
import { useLocation, NavLink, Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
//import AddNew from "./AddNew";
import Popup from "./Popup";

// Import Material Icons
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HomeIcon from "@material-ui/icons/Home";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import SettingsIcon from "@material-ui/icons/Settings";
//import CropFreeIcon from "@material-ui/icons/CropFree";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import SearchIcon from "@material-ui/icons/Search";

const Navigation = () => {
  const [showPopUp, setShowPopup] = useState(false);
  const location = useLocation().pathname;
  const body = document.getElementById("body");

  /*   const addNewPopUp = () => {
    setShowPopup(!showPopUp);
  }; */

  // Handle Popup Mechanism
  const handlePopup = () => {
    setShowPopup(!showPopUp);
  };

  // All the routes you want to exclude
  if (location.includes("/book")) return null;
  if (location.includes("/google-books")) return null;
  if (location.includes("/add-new/")) return null;
  if (location.includes("/login")) return null;
  if (location.includes("/new-collection")) return null;
  if (location.includes("/custom-book")) return null;
  if (location.includes("/log")) return null;
  //if (location.includes("/profile")) return null;

  if (showPopUp) {
    body.classList.add("no-scroll");
  } else {
    body.classList.remove("no-scroll");
  }

  return (
    <>
      <div className="Navigation">
        <NavLink
          to="/"
          activeClassName="active_page"
          exact={true}
          aria-label="home-page"
        >
          <IconButton aria-label="home-icon">
            <HomeIcon />
          </IconButton>
        </NavLink>

        <NavLink
          to="/library"
          activeClassName="active_page"
          aria-label="library-page"
        >
          <IconButton aria-label="library-icon">
            <BookmarksIcon />
          </IconButton>
        </NavLink>

        <IconButton
          className="addNew"
          onClick={handlePopup}
          aria-label="addNew"
        >
          <AddCircleIcon fontSize="large" />
        </IconButton>

        <NavLink
          to="/statistics"
          activeClassName="active_page"
          aria-label="statistics-page"
        >
          <IconButton aria-label="statistics-icon">
            <EqualizerIcon />
          </IconButton>
        </NavLink>

        <NavLink
          to="/profile"
          activeClassName="active_page"
          aria-label="settings-page"
        >
          <IconButton aria-label="settings-icon">
            <SettingsIcon />
          </IconButton>
        </NavLink>
      </div>

      <AnimatePresence>
        {showPopUp && (
          <>
            <motion.div
              className="overlay"
              onClick={handlePopup}
              key="23542"
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3, delay: 0.1 }}
            />
            <Popup handlePopup={handlePopup}>
              <Link to="/google-books" onClick={handlePopup}>
                <li>
                  <SearchIcon />
                  <span>Add new book</span>
                </li>
              </Link>
              <Link to="/custom-book" onClick={handlePopup}>
                <li>
                  <NoteAddIcon />
                  <span>Custom Book</span>
                </li>
              </Link>
              {/* <li className="coming-soon">
                <CropFreeIcon />
                <span>Scan Barcode</span>
              </li> */}
              <Link to="/new-collection" onClick={handlePopup}>
                <li>
                  <CollectionsBookmarkIcon />
                  <span>New Collection</span>
                </li>
              </Link>
            </Popup>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default Navigation;
